import * as React from 'react'
import { groupBy } from 'fp-ts/es6/NonEmptyArray'
import { toArray } from 'fp-ts/es6/Record'
import { pipe } from 'fp-ts/es6/pipeable'
import { getRichText } from '@walltowall/helpers'

import { HNBLocation } from '../PageLayoutLocationsSearch'

import { BoundedBox } from '../../components/BoundedBox'
import { Heading } from '../../components/Heading'
import { LocationCard } from '../../components/LocationCard'
import { Stack } from '../../components/Stack'
import { Columns } from '../../components/Columns'

/**
 * Default island name used if an island not set in Prismic. This shouldn't
 * actually be possible but it's here just in case.
 */
const DEFAULT_ISLAND = 'N/A'

const GROUPED_ISLANDS = ['Hawaii Island', 'Maui']
const GROUP_LABEL = 'Hawaii Island & Maui'
const SORT_ORDER = ['Oahu', GROUP_LABEL]

type SearchResultsProps = {
  locations: HNBLocation[]
}

const _SearchResults = (
  { locations }: SearchResultsProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const locationsGroupedByIsland = pipe(
    locations,
    // Client requested that Hawaii Island and Maui are grouped together.
    // This is determined by the GROUPED_ISLANDS constant.
    groupBy((loc) => {
      const island = loc?.data?.island ?? DEFAULT_ISLAND
      if (GROUPED_ISLANDS.includes(island)) return GROUP_LABEL

      return island
    }),
    toArray,
  ).sort(([a], [b]) => SORT_ORDER.indexOf(a) - SORT_ORDER.indexOf(b))

  return (
    <BoundedBox
      ref={ref}
      innerMaxWidth="medium"
      styles={{
        backgroundColor: 'white',
        color: 'brown20',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <Stack space={[10, 12, 14]}>
        {locationsGroupedByIsland.map(([islandName, locations]) => (
          <Stack key={islandName} space={[6, 7, 8]}>
            <Heading level={3} variant="sansC" styles={{ color: 'beige40' }}>
              {islandName}
            </Heading>
            <Columns space={[4, 5, 6]} count={[1, 2, 3]}>
              {locations.map((loc) => {
                const hours = loc?.data?.hours?.map?.((hours) => ({
                  day: hours?.day,
                  openTime: hours?.open_time,
                  closeTime: hours?.close_time,
                }))

                return (
                  <LocationCard
                    key={loc?.id}
                    title={loc?.data?.title?.text}
                    addressText={loc?.data?.address?.text}
                    addressHTML={getRichText(loc?.data?.address)}
                    phoneNumber={loc?.data?.phone_number}
                    hours={hours}
                    specialNoteHTML={getRichText(loc?.data?.special_note)}
                  />
                )
              })}
            </Columns>
          </Stack>
        ))}
      </Stack>
    </BoundedBox>
  )
}

export const SearchResults = React.forwardRef(_SearchResults)
